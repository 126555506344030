import { type FunctionComponent, type ReactNode, type ChangeEvent, useState } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';
import { Input } from 'dibs-elements/exports/Input';
import { Link } from 'dibs-elements/exports/Link';
import Eye from 'dibs-icons/exports/legacy/Eye';

import styles from './styles/LoginPasswordInput.scss';

type Props = {
    label?: ReactNode;
    name?: string;
    touched: boolean;
    error: ReactNode;
    value: string;
    onBlur: (field: string) => void;
    onChange: (e: ChangeEvent<HTMLInputElement>, field: string) => void;
};

const LoginPasswordInput: FunctionComponent<Props> = ({
    touched,
    error,
    value,
    onBlur,
    onChange,
    label,
    name = 'password',
}) => {
    const [isPasswordHidden, setIsPasswordHidden] = useState(true);

    return (
        <Input
            dataTn={`${name}-input`}
            errorMessage={!!touched && error}
            label={label || <FormattedMessage id="login.password" defaultMessage="Password" />}
            name={name}
            onBlur={() => onBlur(name)}
            onChange={e => onChange(e, name)}
            type={isPasswordHidden ? 'password' : 'text'}
            value={value}
            rightDecorator={
                <Link
                    className={styles.eyeIconButton}
                    onClick={e => {
                        e.stopPropagation();
                        setIsPasswordHidden(!isPasswordHidden);
                    }}
                >
                    <Eye
                        noFill
                        className={classnames(styles.eyeIcon, {
                            [styles.eyeIconCrossedOut]: isPasswordHidden,
                        })}
                    />
                </Link>
            }
            rightDecoratorClass={classnames(styles.eyeIconWrapper, {
                [styles.eyeIconWrapperCrossedOut]: isPasswordHidden,
            })}
        />
    );
};

export default LoginPasswordInput;
