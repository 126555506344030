/**
 * @generated SignedSource<<5a682876ba738d8bfc9ae18219f9186a>>
 * @relayHash f2b65e9b142afbb21275ce56954c9a36
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-admin-accounts/504.0.0-2025-04-01T12:00:37.879Z/recaptchaFeatureFlagQuery

import { ConcreteRequest } from 'relay-runtime';
export type recaptchaFeatureFlagQuery$variables = {
  featureFlag?: string | null;
  featureFlagV3?: string | null;
  hasFeatureFlag: boolean;
  hasFeatureFlagV3: boolean;
};
export type recaptchaFeatureFlagQuery$data = {
  readonly viewer: {
    readonly featureFlag?: boolean | null;
    readonly v3Enabled?: boolean | null;
  };
};
export type recaptchaFeatureFlagQuery = {
  response: recaptchaFeatureFlagQuery$data;
  variables: recaptchaFeatureFlagQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "featureFlag"
  },
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "featureFlagV3"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hasFeatureFlag"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hasFeatureFlagV3"
  }
],
v1 = {
  "condition": "hasFeatureFlag",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "feature",
          "variableName": "featureFlag"
        }
      ],
      "kind": "ScalarField",
      "name": "featureFlag",
      "storageKey": null
    }
  ]
},
v2 = {
  "condition": "hasFeatureFlagV3",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "v3Enabled",
      "args": [
        {
          "kind": "Variable",
          "name": "feature",
          "variableName": "featureFlagV3"
        }
      ],
      "kind": "ScalarField",
      "name": "featureFlag",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recaptchaFeatureFlagQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recaptchaFeatureFlagQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-admin-accounts/504.0.0-2025-04-01T12:00:37.879Z/recaptchaFeatureFlagQuery",
    "metadata": {},
    "name": "recaptchaFeatureFlagQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "07775b1161f29c61d79976d0c04c520d";

export default node;
