import { SAML_REQUEST_PARAM, SAML_RELAY_STATE_PARAM } from './constants';

export const getSamlQueryParams = ({
    samlRequestPayload,
    samlRelayState,
}: {
    samlRequestPayload: string;
    samlRelayState: string;
}): string => {
    const searchParams = new URLSearchParams({
        [SAML_REQUEST_PARAM]: samlRequestPayload,
        [SAML_RELAY_STATE_PARAM]: samlRelayState,
    });

    return searchParams.toString();
};
