import { type FunctionComponent, type ReactNode, type FormEvent } from 'react';
import { useRelayEnvironment } from 'react-relay';

import { BarHeader } from 'dibs-elements/exports/BarHeader';
import LoginWell from './LoginWell';
import DealerLoginCookieDisclaimer from './dealer/DealerLoginCookieDisclaimer';

import styles from './styles/LoginFormWrapper.scss';

type Props = {
    children: ReactNode;
    heading: ReactNode;
    subtitle?: ReactNode;
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
    socialLoginButtons?: ReactNode;
};

const LoginFormWrapper: FunctionComponent<Props> = ({
    children,
    heading,
    subtitle,
    handleSubmit,
    socialLoginButtons,
}) => {
    const environment = useRelayEnvironment();

    return (
        <div className={styles.loginFormWrapper}>
            <LoginWell>
                <BarHeader title={heading} subtitle={subtitle} />
                {socialLoginButtons}
                <form className={styles.form} onSubmit={handleSubmit}>
                    {children}
                </form>
            </LoginWell>
            {environment && <DealerLoginCookieDisclaimer environment={environment} />}
        </div>
    );
};

export default LoginFormWrapper;
