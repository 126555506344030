/**
 * @generated SignedSource<<cc74126d4c3985ed8935d6ce7f8dc696>>
 * @relayHash 6f484e7fbb7cdabdedc7baacf6cc7136
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-admin-accounts/504.0.0-2025-04-01T12:00:37.879Z/sellerRequestPasswordEmailMutation

import { ConcreteRequest } from 'relay-runtime';
export type VerticalType = "art" | "fashion" | "furniture" | "jewelry" | "%future added value";
export type SellerRequestPasswordEmailInput = {
  clientMutationId?: string | null;
  email: string;
  vertical: VerticalType;
};
export type sellerRequestPasswordEmailMutation$variables = {
  input: SellerRequestPasswordEmailInput;
};
export type sellerRequestPasswordEmailMutation$data = {
  readonly sellerRequestPasswordEmail: {
    readonly __typename: "SellerRequestPasswordEmailPayload";
  } | null;
};
export type sellerRequestPasswordEmailMutation = {
  response: sellerRequestPasswordEmailMutation$data;
  variables: sellerRequestPasswordEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SellerRequestPasswordEmailPayload",
    "kind": "LinkedField",
    "name": "sellerRequestPasswordEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sellerRequestPasswordEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sellerRequestPasswordEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "app-admin-accounts/504.0.0-2025-04-01T12:00:37.879Z/sellerRequestPasswordEmailMutation",
    "metadata": {},
    "name": "sellerRequestPasswordEmailMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "88bd524a543d60750ab65e6a97fbfe1e";

export default node;
