import { Fragment, type FC, useState, type FormEvent } from 'react';
import { useRelayEnvironment } from 'react-relay';
import serverVars from 'server-vars';

import { FormattedMessage } from 'dibs-react-intl';
import LoginMessage, { type LoginMessageType } from '../LoginMessage';
import LoginFormWrapper from '../LoginFormWrapper';
import LoginFormGroup from '../inputs/LoginFormGroup';
import LoginPasswordInput from '../inputs/LoginPasswordInput';
import LoginSubmit from '../inputs/LoginSubmit';
import LoginServerErrorMessage from '../LoginServerErrorMessage';
import DealerResetPasswordInvalidTokenErrorMessage from './DealerResetPasswordInvalidTokenErrorMessage';
import DealerLoginForm from './DealerLoginForm';
import { useLoginFormState } from '../helpers/useLoginFormState';
import { sellerResetPassword } from './mutations/sellerResetPassword';
import { type SellerResetPasswordErrorCode } from './mutations/__generated__/sellerResetPasswordMutation.graphql';

const DealerResetPasswordForm: FC = () => {
    const environment = useRelayEnvironment();

    const { errors, isValid, values, touched, handleChange, handleFieldBlur } = useLoginFormState({
        formType: 'resetPassword',
        isInternal: false,
    });
    const [submitted, setSubmitted] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [errorCode, setError] = useState<SellerResetPasswordErrorCode | boolean>(false);

    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!isValid) {
            handleFieldBlur();
            return;
        }
        setIsSaving(true);
        try {
            const results = await sellerResetPassword(environment, {
                passwordToken: serverVars.get('passwordToken'),
                password: values.password,
            });
            const errorCodeResult = results?.sellerResetPassword?.errorCode;

            if (errorCodeResult) {
                setError(errorCodeResult);
                setIsSaving(false);
                return;
            }
            setSubmitted(true);
        } catch (err) {
            setIsSaving(false);
            setError(true);
        }
    }

    if (submitted) {
        return (
            <DealerLoginForm
                successMessage={
                    <FormattedMessage
                        defaultMessage="Your password has been successfully reset."
                        id="login.message.pw_reset_success"
                    />
                }
            />
        );
    }

    let message;
    let messageType: LoginMessageType = 'success';
    if (errorCode) {
        messageType = 'error';
        switch (errorCode) {
            case 'INVALID_TOKEN':
                message = <DealerResetPasswordInvalidTokenErrorMessage />;
                break;
            default:
                message = <LoginServerErrorMessage />;
        }
    }

    return (
        <Fragment>
            {message && <LoginMessage messageType={messageType} message={message} />}

            <LoginFormWrapper
                heading={
                    <FormattedMessage
                        id="login.reset_password_heading"
                        defaultMessage="Create New Password"
                    />
                }
                subtitle={
                    <FormattedMessage
                        id="login.reset_password_subtitle"
                        defaultMessage="Step 3 of 3"
                    />
                }
                handleSubmit={handleSubmit}
            >
                <LoginFormGroup>
                    <LoginPasswordInput
                        label={
                            <FormattedMessage
                                id="login.reset_password_password"
                                defaultMessage="New Password"
                            />
                        }
                        name="password"
                        value={values.password}
                        touched={touched.password}
                        error={errors.password}
                        onBlur={handleFieldBlur}
                        onChange={handleChange}
                    />
                </LoginFormGroup>
                <LoginFormGroup>
                    <LoginPasswordInput
                        label={
                            <FormattedMessage
                                id="login.reset_password_password_confirmation"
                                defaultMessage="Re-enter New Password"
                            />
                        }
                        name="password1"
                        value={values.password1}
                        touched={touched.password1}
                        error={errors.password1}
                        onBlur={handleFieldBlur}
                        onChange={handleChange}
                    />
                </LoginFormGroup>
                <LoginSubmit isSaving={isSaving} />
            </LoginFormWrapper>
        </Fragment>
    );
};

export default DealerResetPasswordForm;
