import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

const DealerLoginServerErrorMessage: FC = () => (
    <FormattedMessage
        defaultMessage="This link is no longer valid. Please use the most recent reset password link you have received within 24 hours."
        id="login.message.reset_expired"
    />
);

export default DealerLoginServerErrorMessage;
