import { type FunctionComponent, type ReactNode, type ChangeEvent } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { Input } from 'dibs-elements/exports/Input';

type Props = {
    touched: boolean;
    error: ReactNode;
    label?: ReactNode;
    onBlur: (field: string) => void;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>, field: string) => void;
};

const LoginEmailInput: FunctionComponent<Props> = ({
    touched,
    error,
    onBlur,
    onChange,
    label,
    value,
}) => (
    <Input
        dataTn="email-input"
        errorMessage={!!touched && error}
        label={label || <FormattedMessage id="login.email" defaultMessage="Email" />}
        name="email"
        onBlur={() => onBlur('email')}
        onChange={e => onChange(e, 'email')}
        value={value}
        type="email"
    />
);

export default LoginEmailInput;
