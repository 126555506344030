import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type EyeProps = {
    className?: string;
    noFill?: boolean;
};

const Eye: FunctionComponent<EyeProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'eye'}
            viewBox="0 0 250 250"
        >
            <path d="M247.7 130.1c-40.4-53.9-83.3-80.4-127.5-78.8-29 1.1-58.7 14.8-85.9 39.7-15.4 14.1-32.5 37.4-32.5 37.5L0 131.4l2.3 2.5c38.2 43.1 79.2 64.9 121.9 64.9h2.8c36.7-.8 67.5-17.9 87-32.2 21-15.3 33.2-30.3 33.7-30.9l2.2-2.7-2.2-2.9zm-121 59.7c-40.1.9-78.9-19-115.4-59.2 9.3-13.2 51.7-68.2 109.3-70.3 40.3-1.5 79.8 22.9 117.7 72.3-10.2 11.4-53.9 55.9-111.6 57.2z" />
            {props.noFill ? (
                <path d="M123.8 91.9c18.5 0 33.5 15 33.5 33.5s-15 33.5-33.5 33.5-33.5-15-33.5-33.5 15.1-33.5 33.5-33.5m0-9c-23.5 0-42.5 19-42.5 42.5s19 42.5 42.5 42.5 42.5-19 42.5-42.5-19-42.5-42.5-42.5z" />
            ) : (
                <path d="M123.8 167.9c23.5 0 42.5-19 42.5-42.5s-19-42.5-42.5-42.5-42.5 19-42.5 42.5 19.1 42.5 42.5 42.5z" />
            )}
        </svg>
    );
};
export default Eye;
