import { type FunctionComponent } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { Button } from 'dibs-elements/exports/Button';
import styles from './styles/LoginSubmit.scss';

type Props = {
    isSaving: boolean;
};

const LoginSubmit: FunctionComponent<Props> = ({ isSaving }) => (
    <div className={styles.formSubmitButton}>
        <Button disabled={isSaving} fullWidth dataTn="login-submit">
            <FormattedMessage id="login.submit" defaultMessage="SUBMIT" />
        </Button>
    </div>
);

export default LoginSubmit;
