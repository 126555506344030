import { type FC, type Dispatch, useEffect, useRef } from 'react';
import { useIntl, defineMessages } from 'dibs-react-intl';

import { trackEvent } from 'dibs-tracking';
import { Input } from 'dibs-elements/exports/Input';
import { Link } from 'dibs-elements/exports/Link';

import dibsCss from 'dibs-css';
import styles from './styles/RecaptchaContainer.scss';
import classnames from 'classnames';

const CODE_LENGTH = 6;

const sharedMessages = defineMessages({
    loginAgain: {
        id: 'dibs.recaptcha.error.loginAgain',
        defaultMessage: 'Please try logging in again.',
    },
});
const messages = defineMessages({
    input: {
        id: 'dibs.recaptcha.verificationCode',
        defaultMessage: 'Verification Code',
    },
    header: {
        id: 'dibs.recaptcha.header',
        defaultMessage: 'Please verify your identity',
    },
    body: {
        id: 'dibs.recaptcha.message',
        defaultMessage:
            // eslint-disable-next-line ferrum/intl-blocklist-elements
            'To ensure your account is secure, we sent a verification code to <a>{email}</a>. Please enter the code in the box below to verify your identity and log into your account. If you are unable to find the message in your inbox, please check your spam folder, or try logging in again.',
    },
    messageCenterBody: {
        id: 'dibs.recaptcha.messageCenterBody',
        defaultMessage:
            // eslint-disable-next-line ferrum/intl-blocklist-elements
            'To ensure your account is secure, we sent a verification code to <a>{email}</a>. Please enter the code in the box below to verify your identity. If you are unable to find the message in your inbox, please check your spam folder, or try logging in again.',
    },
    resetPassword: {
        id: 'dibs.recaptcha.resetPassword',
        defaultMessage:
            "If you don't receive an email, you may have entered the wrong password. Please <a>click here</a> to reset your password.",
    },
    tryLoginAgain: {
        id: 'dibs.recaptcha.loginAgain',
        defaultMessage: "Didn't receive a verification code? Try logging in again",
    },
});

const errorCodes = defineMessages({
    MFA_CODE_EXPIRED: {
        id: 'dibs.recaptcha.error.expired',
        defaultMessage: 'Your verification code has expired. {login}',
    },
    MFA_INVALID_CODE: {
        id: 'dibs.recaptcha.error.invalid',
        defaultMessage: 'Please enter a valid verification code',
    },
    MFA_CODE_ATTEMPTS_EXCEEDED: {
        id: 'dibs.recaptcha.error.exceeded',
        defaultMessage:
            'You have exceeded the amount of times you can enter in that verification code. {login}',
    },
    SUSPICIOUS_ACTIVITY: {
        id: 'dibs.recaptcha.error.suspicious',
        defaultMessage:
            "We've detected suspicious login activity on this device. Please contact support@1stdibs.com",
    },
});

export const errorCodeKeys = Object.keys(errorCodes) as MFAErrorCodes[];

export const isValidErrorCode = (
    code?: MFAErrorCodes | string | boolean | null
): code is MFAErrorCodes => {
    return (
        code === 'MFA_REQUIRED' ||
        code === 'MFA_CODE_EXPIRED' ||
        code === 'MFA_INVALID_CODE' ||
        code === 'MFA_CODE_ATTEMPTS_EXCEEDED' ||
        code === 'SUSPICIOUS_ACTIVITY'
    );
};

export const mfaActionTypes = {
    displayed: 'displayed',
    passed: 'passed',
    loginAgain: 'loginAgain',
} as const;

export type MFAActionType = (typeof mfaActionTypes)[keyof typeof mfaActionTypes];

const trackingActions = {
    displayed: 'mfa verification displayed',
    passed: 'mfa verification submitted',
    loginAgain: 'mfa login again link selected',
} as const;

export type AllowedMFAErrorCodes = keyof typeof errorCodes;
export type MFAErrorCodes = AllowedMFAErrorCodes | 'MFA_REQUIRED';

type TrackingProps = {
    trackingCategory: 'login' | 'update password';
    trackingLabel: string;
    // covered by `mfaActionType`
    // trackingAction: typeof trackingActions[keyof typeof trackingActions];
};

export const validateVerificationCode = (code: string): boolean => {
    return !!code && code.length === CODE_LENGTH && /\w+/gi.test(code);
};

type MarginBottom = Extract<
    keyof typeof dibsCss,
    'mb0' | 'mbXxsmall' | 'mbXsmall' | 'mbSmall' | 'mbMedium' | 'mbLarge' | 'mbLarger'
>;

const RecaptchaContainer: FC<
    {
        email: string;
        resetMFAData?: () => void;
        mfaVerificationCode: string | null;
        setMFAVerificationCode: Dispatch<string>;
        errorCode: MFAErrorCodes | null;
        mfaActionType: MFAActionType;
        shouldAutoClose?: boolean;
        autoCloseDelay?: number;
        isMobile?: boolean;
        size?: Input['props']['size'];
        marginBottom?: MarginBottom;
        isMC?: boolean;
        // INTOOLS-1792 - currently only implemented for buyer side
        resetPasswordFn?: (payload: unknown) => void;
        readOnly?: boolean;
        // temporarily make TrackingProps optional until tracking is decided on for contactDealer
        // updates in https://1stdibs.atlassian.net/browse/INTOOLS-2971
    } & Partial<TrackingProps>
> = ({
    email,
    resetMFAData,
    mfaVerificationCode,
    setMFAVerificationCode,
    trackingCategory,
    trackingLabel,
    mfaActionType = 'displayed',
    shouldAutoClose = false,
    autoCloseDelay = 5000,
    errorCode = null,
    isMobile = false,
    isMC = false,
    resetPasswordFn,
    readOnly,
    size,
    marginBottom = 'mbLarger',
}) => {
    const intl = useIntl();
    const initialMountRef = useRef(false);

    const errorMsg =
        errorCode && errorCode !== 'MFA_REQUIRED' // errorCode can be `MFA_REQUIRED` which does not have messaging
            ? intl.formatMessage(errorCodes[errorCode], {
                  login: intl.formatMessage(sharedMessages.loginAgain),
              })
            : null;
    const shouldTrack =
        !initialMountRef.current ||
        (Object.keys(trackingActions).includes(mfaActionType) && trackingCategory);
    useEffect(() => {
        // fire tracking once on initial load and
        // on successfully passing MFA verification

        if (shouldTrack) {
            trackEvent({
                category: trackingCategory,
                action: trackingActions[mfaActionType],
                label: trackingLabel,
            });
            initialMountRef.current = true;
        }
    }, [trackingCategory, trackingLabel, mfaActionType, setMFAVerificationCode, shouldTrack]);
    useEffect(() => {
        if (shouldAutoClose && autoCloseDelay && typeof resetMFAData === 'function') {
            setTimeout((): void => {
                resetMFAData?.();
            }, autoCloseDelay);
        }
    }, [resetMFAData, shouldAutoClose, autoCloseDelay]);

    return errorCode === 'SUSPICIOUS_ACTIVITY' ? (
        <div
            className={classnames(
                dibsCss.textAlertprimary,
                dibsCss.sassyFontBodyTypeNone,
                dibsCss.mbMedium,
                dibsCss.mtSmall
            )}
        >
            {errorMsg}
        </div>
    ) : (
        <div
            className={classnames({
                [dibsCss[marginBottom]]: !errorMsg,
                [dibsCss.mbSmall]: errorMsg,
            })}
        >
            <div className={styles.header}>{intl.formatMessage(messages.header)}</div>
            <div className={styles.message}>
                {intl.formatMessage(isMC ? messages.messageCenterBody : messages.body, {
                    a: content => <b>{content}</b>,
                    email,
                })}
                {typeof resetPasswordFn === 'function' && (
                    <div>
                        {intl.formatMessage(messages.resetPassword, {
                            a: content => {
                                return (
                                    <Link onClick={payload => resetPasswordFn(payload)}>
                                        {content}
                                    </Link>
                                );
                            },
                        })}
                    </div>
                )}
            </div>
            <div className={styles.inputWrapper}>
                <Input
                    size={size}
                    readOnly={readOnly}
                    autoFocus={!isMobile}
                    value={mfaVerificationCode || ''}
                    maxLength={CODE_LENGTH} // have BE send back a value instead?
                    hasAnimatedPlaceholder
                    dataTn="mfa-code"
                    errorDataTn="mfa-code-error"
                    onChange={e => {
                        const value = e.target.value;
                        setMFAVerificationCode(value);
                    }}
                    placeholder={intl.formatMessage(messages.input)}
                    errorMessage={errorMsg || null}
                    errorMessageAlignment="left"
                    name="verification-code"
                    maskForPrivacy
                />
            </div>
            {typeof resetMFAData === 'function' && (
                <div className={styles.loginAgainCta}>
                    <Link
                        onClick={() => {
                            if (shouldTrack) {
                                trackEvent({
                                    category: trackingCategory,
                                    action: trackingActions.loginAgain,
                                    label: trackingLabel,
                                });
                            }
                            initialMountRef.current = false;
                            // when toggling back to the previous view (ie. login),
                            // then submitting again, the previous error message
                            // may still be stored in cache if the entire parent view
                            // is never reset after the original form submission
                            // (ie. auth modal, submit login, but then never closed then reopened)
                            resetMFAData();
                        }}
                    >
                        {intl.formatMessage(messages.tryLoginAgain)}
                    </Link>
                </div>
            )}
        </div>
    );
};
export default RecaptchaContainer;
