import { Fragment, type FC, type ReactNode, useState, useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';
import serverVars from 'server-vars';
import { FormattedMessage } from 'dibs-react-intl';
import RecaptchaContainer, {
    isValidErrorCode,
    mfaActionTypes,
    type AllowedMFAErrorCodes,
    type MFAActionType,
} from 'dibs-recaptcha/exports/RecaptchaContainer';

import LoginFormWrapper from '../LoginFormWrapper';
import LoginMessage, { type LoginMessageType } from '../LoginMessage';
import LoginFormGroup from '../inputs/LoginFormGroup';
import LoginEmailInput from '../inputs/LoginEmailInput';
import LoginPasswordInput from '../inputs/LoginPasswordInput';
import LoginSubmit from '../inputs/LoginSubmit';
import LoginResetPasswordLink from '../inputs/LoginResetPasswordLink';
import LoginServerErrorMessage from '../LoginServerErrorMessage';
import LoginInvalidErrorMessage from '../LoginInvalidErrorMessage';

import { useLoginFormState } from '../helpers/useLoginFormState';
import { getGoToLocation } from '../helpers/loginGoTo';
import { useLoginMutation } from '../helpers/useLoginMutation';
import { getSamlQueryParams } from '../../saml/getSamlQueryParams';
import { SAML_REQUEST_PARAM, SAML_RELAY_STATE_PARAM } from '../../saml/constants';
import { sellerLoginMutation } from './mutations/sellerLoginMutation';
import { type DibsRelayNetworkError } from 'dibs-relay-network-layer';
import {
    type SellerLoginInput,
    type sellerLoginMutation as sellerLoginMutationType,
} from './mutations/__generated__/sellerLoginMutation.graphql';

type Props = {
    successMessage?: ReactNode;
};

function getErrorMessage(errorCode: boolean | DibsRelayNetworkError): string | boolean | undefined {
    if (typeof errorCode !== 'boolean') {
        return errorCode?.source?.errors?.[0]?.message;
    }
    return errorCode;
}

function isMFAError(errorCode: boolean | DibsRelayNetworkError): boolean {
    const errorMsg = getErrorMessage(errorCode);
    return typeof errorMsg === 'string' && errorMsg.startsWith('MFA_');
}

function isInvalidLogin(errorCode: boolean | DibsRelayNetworkError): boolean {
    const errorMsg = getErrorMessage(errorCode);
    if (typeof errorMsg === 'string') {
        return errorMsg === 'INVALID_LOGIN';
    }
    return false;
}

const DealerLoginForm: FC<Props> = props => {
    const { successMessage } = props;

    const environment = useRelayEnvironment();

    const { errors, isValid, values, touched, handleChange, handleFieldBlur } = useLoginFormState({
        formType: 'login',
        isInternal: false,
    });
    const [message, setMessage] = useState<ReactNode>('');
    const [messageType, setMessageType] = useState<LoginMessageType>('success');
    // MFA
    const [displayRecaptcha, setDisplayRecaptcha] = useState(false);
    const [mfaVerificationCode, setMFAVerificationCode] = useState<string | null>(null);
    const [mfaErrorCode, setMFAErrorCode] = useState<AllowedMFAErrorCodes | null>(null);
    const [mfaActionType, setMFAActionType] = useState<MFAActionType>(mfaActionTypes.displayed);
    // should only auto close when attempts exceeded
    const [mfaShouldAutoClose, setMFAShouldAutoClose] = useState(false);
    // end MFA
    const isMobile = serverVars.get('settings.isMobile');

    const resetMFADefaults = (): void => {
        // allows for the RecaptchaContainer
        // component to reset to default data
        // when toggling views
        setDisplayRecaptcha(false);
        setMFAVerificationCode(null);
        setMFAErrorCode(null);
        setMFAShouldAutoClose(false);
    };

    const searchParams = new URLSearchParams(window.location.search);
    const { errorCode, hasSubmitted, isSaving, handleSubmit } = useLoginMutation<
        sellerLoginMutationType,
        SellerLoginInput,
        boolean
    >({
        isValid,
        mutationFn: sellerLoginMutation,
        variables: {
            email: values.email,
            password: values.password,
            mfaVerificationCode,
            cookieDomain: serverVars.get('cookieDomain'),
            oauth: searchParams.has('client_id')
                ? {
                      clientId: searchParams.get('client_id') || '',
                      redirectUri: searchParams.get('redirect_uri') || '',
                      scope: searchParams.get('scope') || '',
                      state: searchParams.get('state') || '',
                  }
                : null,
        },
        environment,
        handleFieldBlur,
        onSuccess: response => {
            setMFAActionType(mfaActionTypes.displayed);
            const redirectUri = response?.sellerLogin?.redirectUri;
            const samlRequestPayload = searchParams.get(SAML_REQUEST_PARAM);
            const samlRelayState = searchParams.get(SAML_RELAY_STATE_PARAM);

            if (samlRequestPayload && samlRelayState) {
                window.location.assign(
                    `/dealers/saml/?${getSamlQueryParams({
                        samlRequestPayload,
                        samlRelayState,
                    })}`
                );
            } else if (redirectUri) {
                window.location.assign(redirectUri);
            } else {
                window.location.assign(getGoToLocation('/dealers/'));
            }
        },
    });

    useEffect(() => {
        if (errorCode) {
            setMessageType('error');

            if (isMFAError(errorCode)) {
                const msg = getErrorMessage(errorCode);
                if (isValidErrorCode(msg)) {
                    setMFAErrorCode(msg as AllowedMFAErrorCodes);
                    setDisplayRecaptcha(true);
                    if (msg === 'MFA_CODE_ATTEMPTS_EXCEEDED') {
                        setMFAShouldAutoClose(true);
                    }
                }
            } else if (isInvalidLogin(errorCode)) {
                setMessage(<LoginInvalidErrorMessage />);
            } else {
                setMessage(<LoginServerErrorMessage />);
            }
        } else if (successMessage && !hasSubmitted) {
            setMessage(successMessage);
        }
    }, [
        errorCode,
        hasSubmitted,
        successMessage,
        setDisplayRecaptcha,
        setMessage,
        setMessageType,
        setMFAErrorCode,
        setMFAShouldAutoClose,
    ]);

    return (
        <Fragment>
            {message && <LoginMessage messageType={messageType} message={message} />}
            <LoginFormWrapper
                heading={
                    <FormattedMessage id="login.heading" defaultMessage="Seller Account Login" />
                }
                handleSubmit={handleSubmit}
            >
                {displayRecaptcha ? (
                    <RecaptchaContainer
                        resetMFAData={resetMFADefaults}
                        mfaVerificationCode={mfaVerificationCode}
                        setMFAVerificationCode={setMFAVerificationCode}
                        errorCode={mfaErrorCode}
                        trackingCategory="login"
                        trackingLabel="Seller Login"
                        mfaActionType={mfaActionType}
                        isMobile={isMobile}
                        shouldAutoClose={mfaShouldAutoClose}
                        email={values.email}
                    />
                ) : (
                    <>
                        <LoginFormGroup>
                            <LoginEmailInput
                                value={values.email}
                                touched={touched.email}
                                error={errors.email}
                                onBlur={handleFieldBlur}
                                onChange={handleChange}
                            />
                        </LoginFormGroup>
                        <LoginFormGroup>
                            <LoginPasswordInput
                                value={values.password}
                                touched={touched.password}
                                error={errors.password}
                                onBlur={handleFieldBlur}
                                onChange={handleChange}
                            />
                        </LoginFormGroup>
                        <LoginResetPasswordLink href="/login/reset_pw/dealer/new" />
                    </>
                )}

                <LoginSubmit isSaving={isSaving} />
            </LoginFormWrapper>
        </Fragment>
    );
};

export default DealerLoginForm;
