import { graphql, type Environment } from 'react-relay/legacy';
import { commitMutationWithRecaptcha } from 'dibs-recaptcha/exports/commitMutationWithRecaptcha';
import {
    type SellerLoginInput,
    type sellerLoginMutation$data,
    type sellerLoginMutation as sellerLoginMutationType,
} from './__generated__/sellerLoginMutation.graphql';
import { type SupportedMFATypes } from 'dibs-recaptcha/exports/recaptcha';

const mutation = graphql`
    mutation sellerLoginMutation($input: SellerLoginInput!) {
        sellerLogin(input: $input) {
            redirectUri
        }
    }
`;

export async function sellerLoginMutation(
    environment: Environment,
    input: SellerLoginInput
): Promise<sellerLoginMutation$data> {
    const mutationInputVariables = {
        ...input,
    };

    const supportedMFATypes: SupportedMFATypes = ['CODE'];
    const executeCaptchaConfig = {
        action: 'login',
        userName: input.email,
        mfaVerificationCode: input?.mfaVerificationCode,
        supportedMFATypes,
        featureFlag: 'loginCaptchaSeller',
        featureFlagV3: 'loginCaptchaV3Seller',
    };

    return commitMutationWithRecaptcha<
        Partial<SellerLoginInput>,
        sellerLoginMutationType,
        sellerLoginMutation$data
    >(environment, {
        mutation,
        mutationInputVariables,
        executeCaptchaConfig,
    });
}
