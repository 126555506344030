import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type UploadFailedProps = {
    className?: string;
};

const UploadFailed: FunctionComponent<UploadFailedProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'upload-failed'}
            viewBox="0 0 250 250"
        >
            <path d="M124.5 249.3c-68.8 0-124.8-56-124.8-124.8S55.7-.3 124.5-.3s124.8 56 124.8 124.8-56 124.8-124.8 124.8zm0-239.6C61.2 9.7 9.7 61.2 9.7 124.5s51.5 114.8 114.8 114.8 114.8-51.5 114.8-114.8S187.8 9.7 124.5 9.7z" />
            <path d="M125.1 197.2c-6.2 0-11.1-4.9-11.1-11.1 0-6.2 4.9-11.3 11.1-11.3s11.3 5.1 11.3 11.3c.1 6.2-5 11.1-11.3 11.1zm1.6-41.7h-3.1c-1.4-34-8.4-77.7-8.4-84.7 0-6.2 4.1-11.9 10.1-11.9 6.1 0 10.1 5.7 10.1 11.9.1 6.8-6.6 50.3-8.7 84.7z" />
        </svg>
    );
};
export default UploadFailed;
