import { type FC, type ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles/LoginFormGroup.scss';

type Props = {
    children: ReactNode;
    shorten?: boolean;
};

const LoginFormGroup: FC<Props> = ({ children, shorten }) => (
    <div
        className={classNames(styles.formGroup, {
            [styles.shorten]: shorten,
        })}
    >
        {children}
    </div>
);

export default LoginFormGroup;
