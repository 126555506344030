import { useState, type ChangeEvent } from 'react';
import {
    validate,
    type InputValues,
    type TouchedValues,
    type InputErrors,
    type VerticalInputValue,
    type LOGIN_FORM_TYPES,
} from './loginValidation';

type useLoginFormStateReturnType = {
    errors: InputErrors;
    isValid: boolean;
    values: InputValues;
    touched: TouchedValues;
    handleChange: (e: ChangeEvent<HTMLInputElement>, field: string) => void;
    handleFieldBlur: (field?: string) => void;
    handleVerticalSelection: (selection: ChangeEvent<HTMLSelectElement>) => void;
};

export function useLoginFormState({
    formType,
    isInternal,
}: {
    formType: LOGIN_FORM_TYPES;
    isInternal: boolean;
}): useLoginFormStateReturnType {
    const [values, setValues] = useState<InputValues>({
        email: '',
        password: '',
        password1: '',
        vertical: '',
    });

    const [touched, setTouched] = useState<TouchedValues>({
        email: false,
        password: false,
        password1: false,
        vertical: false,
    });

    function handleChange(e: ChangeEvent<HTMLInputElement>, field: string): void {
        const value = e.target.value || '';
        setValues(vals => ({
            ...vals,
            [field]: value,
        }));
    }

    function handleVerticalSelection(e: ChangeEvent<HTMLSelectElement>): void {
        const value = e.target.value as VerticalInputValue;
        setValues(vals => ({
            ...vals,
            vertical: value,
        }));
    }

    function handleFieldBlur(field?: string): void {
        if (field) {
            setTouched(vals => ({
                ...vals,
                [field]: true,
            }));
        } else {
            setTouched({
                email: true,
                password: true,
                password1: true,
                vertical: true,
            });
        }
    }

    const { errors, isValid } = validate(values, formType, isInternal);

    return {
        errors,
        isValid,
        values,
        touched,
        handleChange,
        handleFieldBlur,
        handleVerticalSelection,
    };
}
