import { type ChangeEvent, type ReactNode } from 'react';
import * as React from 'react';
import classnames from 'classnames';

import { RotatingArrow } from '../RotatingArrow';
import { ErrorMessage } from '../ErrorMessage';
import { Field } from '../Common/Field/Field';
import { FormLabel } from '../Common/FormLabel/FormLabel';
import { InputBorderContainer } from '../Common/InputBorderContainer/InputBorderContainer';
import { AnimatedPlaceholderContainer } from '../Common/AnimatedPlaceholderContainer/AnimatedPlaceholderContainer';
import { SIZES, DIRECTIONS } from '../Common/constants';
import { IdConsumer } from 'dibs-uid';

import styles from './main.scss';

type Option = { value: number | string; label: string };
export type BasicSelectOption = { value?: number | string; label: string; options?: Option[] };

export type BasicSelectProps = {
    autoComplete?: string;
    autoFocus?: boolean;
    dataTn: string;
    disabled?: boolean;
    errorDataTn?: string;
    errorMessage?: React.ReactNode | { __html: string };
    errorMessageAlignment?: typeof DIRECTIONS.left | typeof DIRECTIONS.right;
    hasAnimatedPlaceholder?: boolean;
    hasError?: boolean;
    horizontalSpacing?: typeof SIZES.def | typeof SIZES.small;
    isFocused?: boolean;
    label?: string;
    maskForPrivacy?: boolean;
    name?: string;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    onFocus?: () => void;
    options: BasicSelectOption[];
    placeholder?: string;
    size?: typeof SIZES.small | typeof SIZES.medium | typeof SIZES.large;
    value?: number | string;
    ariaLabel?: string;
};

type BasicSelectState = {
    isFocused: boolean;
    value: number | string;
};

export class BasicSelect extends React.Component<BasicSelectProps, BasicSelectState> {
    static defaultProps = {
        autoComplete: '',
        autoFocus: false,
        disabled: false,
        errorMessageAlignment: DIRECTIONS.left,
        hasAnimatedPlaceholder: false,
        hasError: false,
        horizontalSpacing: SIZES.def,
        options: [],
    };

    constructor(props: BasicSelectProps) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.selectElement = null;

        const { autoFocus, isFocused, value } = props;

        this.state = {
            isFocused: autoFocus || !!isFocused,
            value: value || '',
        };
    }

    static getDerivedStateFromProps(
        nextProps: BasicSelectProps,
        prevState: BasicSelectState
    ): { isFocused: boolean; value?: string | number } {
        let value = prevState.value;
        let isFocused = prevState.isFocused;

        /* istanbul ignore else */
        if (typeof nextProps.value !== 'undefined') {
            value = nextProps.value;
        }

        /* istanbul ignore else */
        if (typeof nextProps.isFocused !== 'undefined') {
            isFocused = nextProps.isFocused;
        }
        return {
            isFocused,
            value,
        };
    }

    componentDidUpdate(prevProps: BasicSelectProps, prevState: BasicSelectState): void {
        if (!prevState.isFocused && this.state.isFocused) {
            this.focusInput();
        } else if (prevState.isFocused && !this.state.isFocused) {
            this.blurInput();
        }
    }

    selectElement: HTMLSelectElement | null;

    blurInput = (): void => {
        const selectElement = this.selectElement;

        /* istanbul ignore else */
        if (selectElement) {
            selectElement.blur();
        }
    };

    focusInput = (): void => {
        const selectElement = this.selectElement;

        /* istanbul ignore else */
        if (selectElement) {
            selectElement.focus();
        }
    };

    onBlur = (): void => {
        this.setState({
            isFocused: false,
        });
    };

    onArrowClick = (): void => {
        const { disabled } = this.props;
        const { isFocused } = this.state;

        /* istanbul ignore else */
        if (!disabled && !isFocused) {
            this.onFocus();
        }
    };

    onSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
        const { onChange } = this.props;
        const value = e.target.value;

        this.setState({ value });
        onChange(e);
    };

    onFocus = (): void => {
        this.setState({
            isFocused: true,
        });
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    shouldShowAnimatedPlaceholder = (): boolean => {
        const { hasAnimatedPlaceholder, placeholder } = this.props;
        const { value } = this.state;

        return !!hasAnimatedPlaceholder && !!placeholder && !!value;
    };

    getFakeNativePlaceholder = (animatedPlaceholderRendered: boolean): React.ReactNode | null => {
        const { placeholder } = this.props;
        const { value } = this.state;

        if (!animatedPlaceholderRendered && !!placeholder && !value) {
            return (
                <option value="" disabled>
                    {placeholder}
                </option>
            );
        }
        return null;
    };

    render(): ReactNode {
        const {
            autoComplete,
            autoFocus,
            dataTn,
            disabled,
            errorDataTn,
            errorMessage,
            errorMessageAlignment,
            hasError,
            horizontalSpacing,
            label,
            maskForPrivacy,
            name,
            options,
            placeholder,
            size,
            ariaLabel,
        } = this.props;

        const { isFocused, value } = this.state;

        const showError = hasError || !!errorMessage;
        const errorDataTnString = errorDataTn ? errorDataTn : `${dataTn}-error`;

        const spacing = horizontalSpacing === SIZES.small ? SIZES.small : SIZES.medium;

        return (
            <IdConsumer>
                {htmlId => {
                    // TODO FI-1603
                    // hoist logic: this logic is copypasta in most inputs–could be worth hoisting
                    // into <Field />, along with FormLabel
                    const inputId = htmlId ? `input-${htmlId}` : undefined;
                    // only render hidden placeholder if it can be related via
                    // id, has truthy value, will not be used in place of label
                    // or ariaLabel, and is not redundant with label or
                    // ariaLabel
                    const renderHiddenPlaceholder =
                        !!(inputId && placeholder) &&
                        !!(label || ariaLabel) &&
                        placeholder !== label &&
                        placeholder !== ariaLabel;
                    const placeholderId = renderHiddenPlaceholder
                        ? `placeholder-${inputId}`
                        : undefined;
                    return (
                        <Field>
                            <FormLabel htmlFor={inputId}>{label}</FormLabel>
                            {placeholderId && (
                                <span id={placeholderId} className={styles.hiddenPlaceholder}>
                                    {placeholder}
                                </span>
                            )}
                            <InputBorderContainer
                                disabled={disabled}
                                hasError={showError}
                                isFocused={isFocused}
                                size={size}
                                maskForPrivacy={maskForPrivacy}
                            >
                                <AnimatedPlaceholderContainer
                                    placeholder={placeholder}
                                    showAnimatedPlaceholder={this.shouldShowAnimatedPlaceholder()}
                                    placeholderPaddingLeft={spacing}
                                >
                                    {({ placeholderDidRender }) => {
                                        const fakeNativePlaceholder =
                                            this.getFakeNativePlaceholder(placeholderDidRender);
                                        const wrapperClasses = classnames(styles.selectWrapper, {
                                            [styles.selectAnimatedPlaceholderPadding]:
                                                placeholderDidRender,
                                        });
                                        const selectClasses = classnames(styles.select, {
                                            [styles.withFakeNativePlaceholder]:
                                                !!fakeNativePlaceholder,
                                            [styles.paddingLeftMedium]: spacing === SIZES.medium,
                                            [styles.paddingLeftSmall]: spacing === SIZES.small,
                                        });
                                        return (
                                            <div className={wrapperClasses}>
                                                <select
                                                    autoComplete={autoComplete}
                                                    autoFocus={autoFocus}
                                                    className={selectClasses}
                                                    data-tn={dataTn}
                                                    disabled={disabled}
                                                    name={name}
                                                    onBlur={this.onBlur}
                                                    onChange={this.onSelect}
                                                    onFocus={this.onFocus}
                                                    ref={select => {
                                                        this.selectElement = select;
                                                    }}
                                                    value={value}
                                                    id={inputId}
                                                    aria-label={
                                                        !label
                                                            ? ariaLabel || placeholder
                                                            : undefined
                                                    }
                                                    aria-describedby={placeholderId}
                                                >
                                                    {fakeNativePlaceholder}
                                                    {options.map((option, index) => {
                                                        const {
                                                            label: optionLabel,
                                                            value: optionValue,
                                                            options: optionsGroup,
                                                        } = option || {};
                                                        if (optionsGroup) {
                                                            return (
                                                                <optgroup label={optionLabel}>
                                                                    {optionsGroup.map(
                                                                        (
                                                                            optGroupOption,
                                                                            optGroupOptionIndex
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    optGroupOptionIndex
                                                                                }
                                                                                value={
                                                                                    optGroupOption.value
                                                                                }
                                                                            >
                                                                                {
                                                                                    optGroupOption.label
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </optgroup>
                                                            );
                                                        }
                                                        return (
                                                            <option key={index} value={optionValue}>
                                                                {optionLabel}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        );
                                    }}
                                </AnimatedPlaceholderContainer>
                                <div className={styles.basicSelectArrow}>
                                    <RotatingArrow
                                        onClick={this.onArrowClick}
                                        direction={DIRECTIONS.down}
                                        paddingRight={spacing}
                                    />
                                </div>
                            </InputBorderContainer>
                            <ErrorMessage
                                message={errorMessage}
                                dataTn={errorDataTnString}
                                alignRight={errorMessageAlignment === DIRECTIONS.right}
                            />
                        </Field>
                    );
                }}
            </IdConsumer>
        );
    }
}
